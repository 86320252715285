import React, { useState } from 'react';
import './Appbar.css';
import DehazeIcon from '@mui/icons-material/Dehaze';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PersonIcon from '@mui/icons-material/Person';
import CodeIcon from '@mui/icons-material/Code';
import CallIcon from '@mui/icons-material/Call';

function Appbar() {
    const [state, setState] = useState({
        right: false
    });
    const [changeNavbarColor, setChangeNavbarColor] = useState(false);

    const changeColor = () => {
        if (window.scrollY > 550) {
            setChangeNavbarColor(true);
        } else {
            setChangeNavbarColor(false);
        }
        // console.log(window.scrollY)
    }

    window.addEventListener('scroll', changeColor)
    const theme = {
        width: "50vw",
        backgroundColor: "#25292e",
        height: "100vh",
        color: "#1cb698",
        padding: "2rem",
        borderLeft: "3px solid #1cb698",
        ['@media (max-width:596px)']: { // eslint-disable-line no-useless-computed-key
            width: "75vw"
        }
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const list = (anchor) => (
        <Box
            sx={theme}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="close-btn-container">
                <IconButton style={{ color: "#1cb698" }} onClick={toggleDrawer('right', false)} aria-label="close">
                    <CloseRoundedIcon />
                </IconButton>
            </div>
            <div >
                <div className="nav-links-sidebar">
                    <List>
                        <div className="navlink-item">
                            <ListItem button>
                                <ListItemIcon>
                                    <HomeRoundedIcon style={{ color: "#1cb698" }} />
                                </ListItemIcon>
                                <a href="#home" className="text-decoration-none" style={{ color: "white" }}> <ListItemText primary="HOME" /></a>
                            </ListItem>
                            <Divider />
                        </div>
                        <div className="navlink-item">
                            <ListItem button>
                                <ListItemIcon>
                                    <PersonIcon style={{ color: "#1cb698" }} />
                                </ListItemIcon>
                                <a href="#about-me" className="text-decoration-none" style={{ color: "white" }}><ListItemText primary="ABOUT" /></a>
                            </ListItem>
                            <Divider />
                        </div>
                        <div className="navlink-item">
                            <ListItem button>
                                <ListItemIcon>
                                    <CodeIcon style={{ color: "#1cb698" }} />
                                </ListItemIcon>
                                <a href="#skills" className="text-decoration-none" style={{ color: "white" }}><ListItemText primary="SKILLS" /></a>
                            </ListItem>
                            <Divider />
                        </div>
                        <div className="navlink-item">
                            <ListItem button>
                                <ListItemIcon style={{ color: "#1cb698" }}>
                                    <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                                </ListItemIcon>
                                <a href="#qualification" className="text-decoration-none" style={{ color: "white" }}> <ListItemText primary="QUALIFICATION" /></a>
                            </ListItem>
                            <Divider />
                        </div>
                        {/* <div className="navlink-item">
                            <ListItem button>
                                <ListItemIcon style={{ color: "#1cb698" }}>
                                    <i className="fa fa-book" aria-hidden="true"></i>
                                </ListItemIcon>
                                <a href="#portfolio" className="text-decoration-none" style={{ color: "white" }}><ListItemText primary="PORTFOLIO" /></a>
                            </ListItem>
                            <Divider />
                        </div> */}
                        <div className="navlink-item">
                            <ListItem button>
                                <ListItemIcon>
                                    <CallIcon style={{ color: "#1cb698" }} />
                                </ListItemIcon>
                                <a href="#contact" className="text-decoration-none" style={{ color: "white" }}> <ListItemText primary="CONTACT" /></a>
                            </ListItem>
                            <Divider />
                        </div>
                    </List>
                </div>
            </div>
        </Box>
    );
    return (
        <nav className={`navbar navbar-expand-lg nav-container ${changeNavbarColor ? 'changeNavColor' : null}`}>
            <div className="container-fluid">
                <span className="navbar-brand nav-title" href="...">Karthi <span className="nav-initial">M</span></span>
                <div className="navbar-toggler btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span >
                        <IconButton style={{ color: "#1cb698" }} onClick={toggleDrawer('right', true)} aria-label="toggle">
                            <DehazeIcon />
                        </IconButton>
                        <Drawer
                            anchor={'right'}
                            open={state['right']}
                            onClose={toggleDrawer('right', false)}
                        >
                            {list('right')}
                        </Drawer>
                    </span>
                </div>
                <div className="collapse navbar-collapse nav-contents" id="navbarNavAltMarkup">
                    <div className="navbar-nav nav-links">
                        {/* link-active */}
                        <a className="nav-link text-uppercase" href="#home">Home</a>
                        <a className="nav-link text-uppercase" href="#about-me">About</a>
                        <a className="nav-link text-uppercase" href="#skills">Skills</a>
                        <a className="nav-link text-uppercase" href="#qualification">Qualification</a>
                        {/* <a className="nav-link text-uppercase" href="#portfolio">Portfolio</a> */}
                        <a className="nav-link text-uppercase" href="#contact">Contact</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Appbar
