import './App.css';
import About from './Components/About';
import Appbar from './Components/Appbar';
import Mainpage from './Components/Mainpage';
import Skills from './Components/Skills';
import Qualification from './Components/Qualification';
// import Portfolio from './Components/Portfolio';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

function App() {
  return (<>
      <Appbar />
    {/* <div className="snap"> */}
      <div className="full-container">
        <Mainpage />
      </div>
      <About />
      <Skills />
      <Qualification />
      {/* <Portfolio /> */}
      <Contact />
      <Footer />
    {/* </div> */}
  </>

  );
}

export default App;
