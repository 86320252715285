import React from 'react';
import './About.css';
import myImg from './Images/profile.jpg';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import CakeIcon from '@mui/icons-material/Cake';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import RoomIcon from '@mui/icons-material/Room';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import download from 'downloadjs';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
function About() {
    const details = [
        {
            detail: "Name",
            ans: "Karthi M",
            icon: PersonRoundedIcon
        },
        {
            detail: "Birthdate",
            ans: "30-11-1993",
            icon: CakeIcon
        },
        {
            detail: "Email",
            ans: "karthi.m.official@gmail.com",
            icon: EmailIcon
        },
        {
            detail: "Phone",
            ans: "+91 7305805670",
            icon: CallIcon
        },
        {
            detail: "Languages",
            ans: "English, Tamil, Telugu",
            icon: GTranslateIcon
        },
        {
            detail: "Address",
            ans: `155/156 Venkatajalapathy Nagar,
            Gudiyattam, Vellore.`,
            icon: RoomIcon
        },
        {
            detail: "Zipcode",
            ans: "632602",
            icon: LocationCityIcon
        }
    ]
    const downloadResume = async () => {
        toast.loading('downloading...');
        let res = await axios.get(`https://portfolio-api.gravitycodes.com/resume`, { responseType: "blob" });
        if (res.data) {
            download(res.data, 'Karthi_munirathinam.pdf')
            toast.remove();
            toast.success('downloaded');
        } else {
            toast.error('Something went wrong!')
        }
    }
    return (
        <div className="about-container container-fluid" id="about-me">
            <div className="row">
                <div className="col-12 text-center">
                    <h1 className="about-title pb-2">ABOUT ME</h1>
                </div>
            </div>
            <div className="row about-content-container">
                <div className="col-lg-6 abt-img-container">
                    <img src={myImg} alt="aboutPicture" className="aboutimage img-fluid" />
                </div>
                <div className="col-lg-6 abt-text-container">
                    <div className="row">
                        <p className="col-12">I am <span className="about-name">Karthi M</span>. A highly skilled and motivated Full Stack Developer with a specialization in <b>backend web development</b>.</p>
                    </div>
                    <div className="row">
                        <p className="col-12">I offer a solid foundation in designing, building, and optimizing the server-side components of web applications. With more than <b>two years</b> of hands-on experience in this domain, I have honed my expertise in creating robust, efficient, and scalable backend solutions that drive the seamless functioning of web platforms.</p>
                    </div>
                    <div className="row">
                        {
                            details.map(each => {
                                return (
                                    <div className="col-12 p-2 about-detail-container" key={each.detail} >
                                        {/* <div className="row "> */}
                                        <div className="about-icon col-2"><each.icon /></div>
                                        <div className="about-dash col-1"></div>
                                        <div className="about-details col-9">
                                            {each.detail}: <span className="about-detail-ans">{each.ans}</span>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="row resume-container">
                <div className="col-12 text-center btn-container">
                    <Button variant="contained" endIcon={<DownloadIcon />} onClick={downloadResume} >
                        Resume
                    </Button>
                </div>
            </div>
            <Toaster position="top-center"
                reverseOrder={false} />
        </div >
    )
}

export default About
