import React from 'react'

function Qualificationbar({ mydata }) {
    return (
        <div className="col-12">
            <div className="bar-container">
                <div className="bar-icon-container">
                    <div className="bar-icon-line"></div>
                    <div className="bar-icon"><i className={(mydata.type) ? "fa fa-graduation-cap" : "fa fa-briefcase"} aria-hidden="true"></i></div>
                </div>
                <div className="bar-dash"></div>
                <div className="bar-content">
                    <div className="bar-title">
                        {mydata.degree}
                    </div>
                    <div className="bar-year">
                        {mydata.year} <span style={{ color: "#666" }}>|</span> <span className="bar-institute">{mydata.institute}</span>
                    </div>
                    {
                         (mydata.type)  ?
                            <div className="bar-cgpa">
                                {mydata.type} : <span className="bar-mark">{mydata.mark}</span>
                            </div>
                         : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Qualificationbar
