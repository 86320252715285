import React, { useCallback } from 'react';
import './Mainpage.css';
import profile from './Images/beach.jpeg';
import Particles from 'react-tsparticles';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { motion } from "framer-motion"
import { loadFull } from "tsparticles";

function Mainpage() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);
    
    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);
    return (
        <div className='mainpage-con'>
            <div className="container-fluid mainpage-container" id="home">
                <section className="content-container row">
                    <div className="col-12 image-container">
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            transition={{ duration: 0.3 }}
                            className="row item">
                            <img src={profile} className="profile-pic" alt="profileImage" />
                        </motion.div>
                        <div className="circle" style={{ animationDelay: "-3s" }}></div>
                        <div className="circle" style={{ animationDelay: "-2s" }}></div>
                        <div className="circle" style={{ animationDelay: "-1s" }}></div>
                        <div className="circle" style={{ animationDelay: "0s" }}></div>
                    </div>
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.3 }} className="col-12 text-center">
                        <h1 className="name">I'm Karthi</h1>
                    </motion.div>
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.3 }} className="col-12 text-center">
                        <h3 className="mern">Full Stack Developer | MERN</h3>
                    </motion.div>
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.3 }} className="col-12 text-center icons-container">
                        <a href="https://github.com/Karthi-Munirathinam" target="_blank" rel="noreferrer"><button className="icon-buttons"><GitHubIcon /></button></a>
                        <a href="https://www.linkedin.com/in/karthi-munirathinam-0812166a" target="_blank" rel="noreferrer"><button className="icon-buttons"><LinkedInIcon /></button></a>
                       {/* <a href="https://www.facebook.com/Karthi268" target="_blank" rel="noreferrer"><button className="icon-buttons"><FacebookIcon /></button></a>
                        <a href="https://twitter.com/karthiraja007" target="_blank" rel="noreferrer"><button className="icon-buttons"><TwitterIcon /></button></a>*/}
                        <a href="https://www.instagram.com/karthi_munirathinam" target="_blank" rel="noreferrer"><button className="icon-buttons"><InstagramIcon /></button></a>
                    </motion.div>
                </section >
            </div >
            {/* <Particles
                params={
                    {
                        particles: {
                            number: {
                                value: 100,
                                density: {
                                    enable: true,
                                    value_area: 1000
                                }
                            },
                            color: {
                                value: "#ffffff"
                            },
                            shape: {
                                type: "circle",
                                stroke: {
                                    width: 0,
                                    color: "#000000"
                                },
                                polygon: {
                                    nb_sides: 12
                                }
                            },
                            opacity: {
                                value: 0.5,
                                random: false,
                                anim: {
                                    enable: false,
                                    speed: 1,
                                    opacity_min: 0.1,
                                    sync: false
                                }
                            },
                            size: {
                                value: 5,
                                random: true,
                                anim: {
                                    enable: false,
                                    speed: 50,
                                    size_min: 0.1,
                                    sync: false
                                }
                            },
                            line_linked: {
                                enable: true,
                                distance: 150,
                                color: "#ffffff",
                                opacity: 0.4,
                                width: 1
                            },
                            move: {
                                enable: true,
                                speed: 8,
                                direction: "none",
                                random: false,
                                straight: false,
                                out_mode: "out",
                                bounce: false,
                                attract: {
                                    enable: false,
                                    rotateX: 600,
                                    rotateY: 1200
                                }
                            }
                        },
                        interactivity: {
                            detect_on: "canvas",
                            events: {
                                onhover: {
                                    enable: false,
                                    mode: "repulse"
                                },
                                onclick: {
                                    enable: true,
                                    mode: "push"
                                },
                                resize: true
                            },
                            modes: {
                                grab: {
                                    distance: 800,
                                    line_linked: {
                                        opacity: 1
                                    }
                                },
                                bubble: {
                                    distance: 400,
                                    size: 40,
                                    duration: 2,
                                    opacity: 1,
                                    speed: 3
                                },
                                repulse: {
                                    distance: 100,
                                    duration: 0.4
                                },
                                push: {
                                    particles_nb: 4
                                },
                                remove: {
                                    particles_nb: 2
                                }
                            }
                        },
                        retina_detect: true
                    }

                }
                className="particle"
            /> */}
            <Particles id="tsparticles-custom" className="particle" params={
                    {
                        particles: {
                            number: {
                                value: 100,
                                density: {
                                    enable: true,
                                    value_area: 1000
                                }
                            },
                            color: {
                                value: "#ffffff"
                            },
                            shape: {
                                type: "circle",
                                stroke: {
                                    width: 0,
                                    color: "#000000"
                                },
                                polygon: {
                                    nb_sides: 12
                                }
                            },
                            opacity: {
                                value: 0.5,
                                random: false,
                                anim: {
                                    enable: false,
                                    speed: 1,
                                    opacity_min: 0.1,
                                    sync: false
                                }
                            },
                            size: {
                                value: 5,
                                random: true,
                                anim: {
                                    enable: false,
                                    speed: 50,
                                    size_min: 0.1,
                                    sync: false
                                }
                            },
                            line_linked: {
                                enable: true,
                                distance: 150,
                                color: "#ffffff",
                                opacity: 0.4,
                                width: 1
                            },
                            move: {
                                enable: true,
                                speed: 8,
                                direction: "none",
                                random: false,
                                straight: false,
                                out_mode: "out",
                                bounce: false,
                                attract: {
                                    enable: false,
                                    rotateX: 600,
                                    rotateY: 1200
                                }
                            }
                        },
                        interactivity: {
                            detect_on: "canvas",
                            events: {
                                onhover: {
                                    enable: false,
                                    mode: "repulse"
                                },
                                onclick: {
                                    enable: true,
                                    mode: "push"
                                },
                                resize: true
                            },
                            modes: {
                                grab: {
                                    distance: 800,
                                    line_linked: {
                                        opacity: 1
                                    }
                                },
                                bubble: {
                                    distance: 400,
                                    size: 40,
                                    duration: 2,
                                    opacity: 1,
                                    speed: 3
                                },
                                repulse: {
                                    distance: 100,
                                    duration: 0.4
                                },
                                push: {
                                    particles_nb: 4
                                },
                                remove: {
                                    particles_nb: 2
                                }
                            }
                        },
                        fullScreen: {
                            enable: false
                          },
                        retina_detect: true,
                    }

                }  init={particlesInit} loaded={particlesLoaded} height="50" />
        </div >
    )
}

export default Mainpage
