// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Components/Images/bg.JPG";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* * {\r\n  font-family: \"Montserrat\", sans-serif;\r\n  font-family: \"Righteous\", cursive;\r\n  font-family: \"Work Sans\", sans-serif;\r\n} */\r\n.full-container {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  object-fit: cover;\r\n  background-position: bottom;\r\n  background-size: cover;\r\n  background-blend-mode: multiply;\r\n  background-color: #1e2022;\r\n  background-repeat: repeat-y;\r\n}\r\n.snap {\r\n  scroll-snap-type: y mandatory;\r\n  /* scroll-behavior: smooth;\r\n  overflow-y: scroll; */\r\n  /* min-height: 100vh; */\r\n}\r\n", "",{"version":3,"sources":["webpack://src/App.css"],"names":[],"mappings":"AAAA;;;;GAIG;AACH;EACE,yDAAiD;EACjD,iBAAiB;EACjB,2BAA2B;EAC3B,sBAAsB;EACtB,+BAA+B;EAC/B,yBAAyB;EACzB,2BAA2B;AAC7B;AACA;EACE,6BAA6B;EAC7B;uBACqB;EACrB,uBAAuB;AACzB","sourcesContent":["/* * {\r\n  font-family: \"Montserrat\", sans-serif;\r\n  font-family: \"Righteous\", cursive;\r\n  font-family: \"Work Sans\", sans-serif;\r\n} */\r\n.full-container {\r\n  background-image: url(./Components/Images/bg.JPG);\r\n  object-fit: cover;\r\n  background-position: bottom;\r\n  background-size: cover;\r\n  background-blend-mode: multiply;\r\n  background-color: #1e2022;\r\n  background-repeat: repeat-y;\r\n}\r\n.snap {\r\n  scroll-snap-type: y mandatory;\r\n  /* scroll-behavior: smooth;\r\n  overflow-y: scroll; */\r\n  /* min-height: 100vh; */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
