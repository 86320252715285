import React from 'react';
import './Footer.css';
import { motion } from "framer-motion";
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {
    return (
        <div className="footer-container container-fluid">
            <div className="row">
                <div className="up"><a href="#home" style={{ color: "white" }} className="text-decoration-none">UP</a></div>
            </div>
            <div className="row mylinks-container">
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.3 }} className="col-12 text-center myicons-container">
                    <a href="https://github.com/Karthi-Munirathinam" target="_blank" rel="noreferrer"><button className="myicon-buttons"><GitHubIcon /></button></a>
                    <a href="https://www.linkedin.com/in/karthi-munirathinam-0812166a" target="_blank" rel="noreferrer"><button className="myicon-buttons"><LinkedInIcon /></button></a>
                    <a href="https://www.facebook.com/Karthi268" target="_blank" rel="noreferrer"><button className="myicon-buttons"><FacebookIcon /></button></a>
                    <a href="https://twitter.com/karthiraja007" target="_blank" rel="noreferrer"><button className="myicon-buttons"><TwitterIcon /></button></a>
                    <a href="https://www.instagram.com/karthi_munirathinam" target="_blank" rel="noreferrer"><button className="myicon-buttons"><InstagramIcon /></button></a>
                </motion.div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h6 className="text-center copyright">&#169; Copyright 2021 <span className="copyright-name">Karthi</span>. All rights reserved.</h6>
                </div>
            </div>
        </div>
    )
}

export default Footer
