import React from 'react';
import './Contact.css'
import ContactCard from './ContactCard';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import { useFormik } from 'formik';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
function Contact() {
    const contactdata = [
        {
            title: "LOCATION",
            icon: LocationOnIcon,
            info: ["Gudiyatham, Vellore", "Tamil Nadu"]
        },
        {
            title: "EMAIL",
            icon: EmailIcon,
            info: ["karthi.m.official@gmail.com", "karthiraja268@gmail.com"]
        },
        {
            title: "PHONE",
            icon: CallIcon,
            info: ["+91 7305805670"]
        }
    ]
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: ''
        },
        validate: (values) => {
            let errors = {};
            if (!values.name) {
                errors.name = "Name required"
            } else if (values.name.length < 3) {
                errors.name = "Name must contain atleast 3 characters"
            }
            if (!values.email) {
                errors.email = "Email required"
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = "Invalid email address"
            }
            if (!values.subject) {
                errors.subject = "Subject required"
            } else if (values.subject.length < 5) {
                errors.subject = "Subject must contain atleast 5 characters"
            }
            if (!values.message) {
                errors.message = "Message required"
            }
            return errors;
        },
        onSubmit: (values, { resetForm }) => {
            const postMessage = async () => {
                try {
                    let postdata = await axios.post(`https://portfolio-api.gravitycodes.com/contact`, {
                        name: values.name,
                        email: values.email.toLowerCase(),
                        subject: values.subject,
                        message: values.message
                    });
                    if (postdata.data.message === "Thank you!") {
                        toast.success('Message sent!')
                    }
                    resetForm({ values: '' })

                } catch (error) {
                    console.log(error);
                }
            }
            postMessage();
        }
    })
    return (
        <div className="contact-container container-fluid" id="contact">
            <div className="row">
                <div className="col-12 text-center">
                    <h1 className="contact-title pb-2">KEEP IN TOUCH</h1>
                </div>
            </div>
            <div className="row pt-3 contact-me-container">
                <div className="col-lg-6 contact-input-container">
                    <form className="contact-input-form" onSubmit={formik.handleSubmit} method="post">
                        <div className="input-text-container">
                            <input type="text" className="name-input col-12" value={formik.values.name} onChange={formik.handleChange} name="name" placeholder="Name" />
                            {formik.errors.name ? <span className="errors col-12">{formik.errors.name}</span> : null}
                        </div>
                        <div className="input-text-container">
                            <input type="email" className="email-input col-12" value={formik.values.email} onChange={formik.handleChange} name="email" placeholder="Email" />
                            {formik.errors.email ? <span className="errors col-12">{formik.errors.email}</span> : null}
                        </div>
                        <div className="input-text-container">
                            <input type="text" className="subject-input col-12" value={formik.values.subject} onChange={formik.handleChange} name="subject" placeholder="Subject" />
                            {formik.errors.subject ? <span className="errors col-12">{formik.errors.subject}</span> : null}
                        </div>
                        <div className="input-text-container">
                            <textarea name="message" className="col-12 message-input" value={formik.values.message} onChange={formik.handleChange} placeholder="Message"></textarea>
                            {formik.errors.message ? <span className="errors col-12">{formik.errors.message}</span> : null}
                        </div>
                        <input type="submit" className="col-12 send-message-btn" value={`Send Message`} />
                    </form>
                    <Toaster position="top-center"
                        reverseOrder={false} />
                </div>
                <div className="col-lg-6 ">
                    {
                        contactdata.map((eachdata, index) => {
                            return <ContactCard key={index} mycontact={eachdata} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Contact
