import React from 'react';
import './Qualification.css'
import Qualificationbar from './Qualificationbar';

function Qualification() {
    const data = [
        {
            degree: "Full Stack Engineer",
            year: "2021 - Present",
            institute: "Bytize Technology Solutions Pvt Ltd",
        },
        {
            degree: "B.Tech Aeronautical",
            year: "2012 - 2016",
            institute: "Hindustan University",
            type: "CGPA",
            mark: "8.98"
        },
        {
            degree: "HSC",
            year: "2011 - 2012",
            institute: "National Higher Secondary School",
            type: "Percentage",
            mark: "89%"
        },
        {
            degree: "SSLC",
            year: "2009 - 2010",
            institute: "Holy Angels Matric School",
            type: "Percentage",
            mark: "80%"
        }
    ]
    return (
        <div className="qualification-container container-fluid" id="qualification">
            <div className="row">
                <div className="col-12 qualification-title-container text-center">
                    <h1 className="qualification-title pb-2">QUALIFICATION & EXPERIENCE</h1>
                </div>
            </div>
            <div className="row p-3">
                {
                    data.map(each => {
                        return <Qualificationbar mydata={each} key={each.degree} />
                    })
                }
            </div>

        </div>
    )
}

export default Qualification
