import React from "react";
import Progressbar from "./Progressbar";
import "./Skills.css";

function Skills() {
  const data = [
    {
      title: "JavaScript",
      percentage: "90",
    },
    {
      title: "TypeScript",
      percentage: "90",
    },
    {
      title: "Nest Js",
      percentage: "95",
    },
    {
      title: "Node JS & Express JS",
      percentage: "90",
    },
    {
      title: "HTML5",
      percentage: "89",
    },
    {
      title: "CSS3",
      percentage: "85",
    },
    {
      title: "React JS",
      percentage: "80",
    },
    {
      title: "Mongodb",
      percentage: "95",
    },
    {
      title: "MySQL",
      percentage: "80",
    },
    {
      title: "Redis",
      percentage: "90",
    },
    {
        title: 'AWS (EC2, S3, SNS, SQS)',
        percentage: '80'
    },
    {
      title: "BootStrap & MaterialUI",
      percentage: "80",
    },
  ];
  return (
    <div className="skills-container container-fluid" id="skills">
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="skill-title pb-2">MY SKILLS</h1>
        </div>
      </div>
      <div className="skill-bar-container">
        <div className="row">
          {data.map((each, index) => {
            return (
              <Progressbar
                skill={each.title}
                progress={each.percentage}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Skills;
