import React from 'react';

function ContactCard({ mycontact }) {
    return (
        <div className="row p-2">
            <div className="col-12">
                <div className="contact-card-container">
                    <div className="contact-icon-container">
                        <div className="contact-card-top">
                            <div className="contact-dash col-1"></div>
                            <div className="contact-icon col-2"><mycontact.icon /></div>
                            <div className="contact-topic col-9">
                                {mycontact.title}
                            </div>
                        </div>
                        <div className="contact-card-bottom">
                            {
                                mycontact.info.map((each, index) => {
                                    return <div className="contact-info" key={index}>{each}</div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactCard
