import React from 'react';

function Progressbar({ skill, progress }) {
    return (
        <div className="col-lg-4 col-md-6 p-4">
            <div className="progress-title pb-1">
                <h4>{skill}</h4>
            </div>
            <div className="progress progress-container">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${progress}%`, backgroundColor: "#1cb698", color: "#25292e", fontWeight: "bold" }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
            </div>
        </div>
    )
}

export default Progressbar
